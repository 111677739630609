import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { forUsPages } from '../data/data';
import loadable from '@loadable/component';

const Grid = loadable(() => import('../components/grid'));
const CardWithoutImage = loadable(() => import('../components/card-without-image'));

const AboutUs = ({ data }) => {
  const metaData = {
    title: 'За Нас'
  };

  return (
    <Layout metaData={metaData}>
      <Grid mode="dark">
        {forUsPages.map(page => (
          <CardWithoutImage
            key={page.id}
            title={page.title}
            bg={data[page.id] ? data[page.id].childImageSharp.fluid.src : ''}
            url={page.slug}
          />
        ))}
      </Grid>
    </Layout>
  );
};

export default AboutUs;

export const query = graphql`
  query forUs {
    history: file(name: { eq: "history-cover" }) {
      ...mediumImage
    }
    partners: file(name: { eq: "partners-cover" }) {
      ...mediumImage
    }
    competitions: file(name: { eq: "competitions-cover" }) {
      ...mediumImage
    }
    awards: file(name: { eq: "awards-cover" }) {
      ...mediumImage
    }
    albums: file(name: { eq: "albums-cover" }) {
      ...mediumImage
    }
  }
`;
